import React from 'react';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import ClipboardCopy from '../../widgets/clipboard_copy';
import UpdateEmailModal from './../update_email';
import AddDailyLimitModal from '../add_daily_limit';
import UpdateDailyLimitModal from '../update_daily_limit'
import RemoveDailyLimit from '../remove_daily_limit'
import AllowedDailyLimitModal from '../allowed_daily_limit'
import AddBonusModel from '../add_bonus';
import ResetBonusModel from '../reset_bonus';
import UpdateMemberStatus from '../update_member_status';
import UpdateTimezoneModal from '../update_timezone';
import UpdateRateLimitModal from '../update_api_rate_limit';
import RemoveRateLimitModel from '../remove_api_rate_limit';
import * as Helpers from '../../../helpers/helper'
import * as IndividualUserConstants from '../helpers/constant'
import ClickToCopy from '../../widgets/copy_onclick';
import * as Constants from '../../../helpers/constants'
import CustomOverlayTrigger from '../../widgets/overlay_trigger'


export const emailFormatter = ({ client, value, userData, user_id, getUserInfo }) => {
  return (
    <div className="d-flex flex-wrap">
      <div className='d-flex'>
        <CustomOverlayTrigger
          content={value}
          placement='top'
        >
          <span className="d-flex align-items-center">
            <span className="me-2">{Helpers.truncateEmail(value, 20)}</span>
            <ClipboardCopy textToCopy={value} />
          </span>
        </CustomOverlayTrigger>
      </div>
      <div className="px-2">
        {_.includes(IndividualUserConstants.BASIC_INFO[client].SHOW_BUTTONS, 'edit_email') ? (
          <UpdateEmailModal
            client={client}
            couserid={user_id}
            getUserInfo={getUserInfo}
            data={userData}
          />
        ) : null}
      </div>
    </div>

  )
}

export const phoneFormatter = ({ value }) => {
  return (
    <div className='d-flex'>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </div>
  )
}

export const UserRole = ({ value }) => {
  return (
    <p>{value === 'analyst' ? 'Executive' : Helpers.capitalizeFirstLetter(value)}</p>
  )
}

export const remainingCreditsFormatter = ({ client, userData, getUserInfo }) => {
  return (
    <div className="d-flex gap-2 remaining-credits">
      {userData.user_type === 'individual' ? (
        <AddBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          email={userData.email}
          userData={userData}
        />
      ) : null}
      {userData.user_type === 'individual' ? (
        <ResetBonusModel
          client={client}
          getUserInfo={getUserInfo}
          name={userData.name}
          userData={userData}
        />
      ) : null}
    </div>
  )
}

export const jobTitleFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.job_title
        ? Helpers.capitalizeFirstLetter(additionalData.job_title)
        : '-'}
    </>
  )
}

export const industryFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData && additionalData.industry
        ? Helpers.capitalizeFirstLetter(additionalData.industry)
        : '-'}
    </>
  )
}

export const signupFormatter = ({ additionalData }) => {
  return (
    <>
      {additionalData?.user_profile_additional_info && additionalData.user_profile_additional_info.signup_source && additionalData.user_profile_additional_info.signup_method
        ? `${Helpers.capitalizeFirstLetter(additionalData.user_profile_additional_info.signup_source)} / ${Helpers.capitalizeFirstLetter(additionalData.user_profile_additional_info.signup_method)}`
        : 'Direct / Email'}
    </>
  )
}

export const companyNameFormatter = ({ additionalData }) => {
  return (
    <>s
      {additionalData && additionalData.organization_name
        ? Helpers.capitalizeFirstLetter(additionalData.organization_name)
        : '-'}
    </>
  )
}

export const userIdFormatter = ({ value }) => {
  return (
    <div className='text-break'>
      {value ? (
        <ClickToCopy text={value} />
      ) : '-'}
    </div>
  )
}

export const orgIdFormatter = ({ value, userData, client }) => {
  return (
    <div className='d-flex'>
      <div>
        {value ? (
          <ClickToCopy text={value} />
        ) : '-'}
      </div>
      <div className='px-1'>
        {userData.user_type === 'organization' ? (
          <>
            <Link
              to={`/${client}/organization_info?org_id=${userData.org_id}`}
              title="org info"
              className='ms-1'
            >
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className='text-primary'
              />
            </Link>
          </>
        ) : null}
      </div>
    </div>
  )
}

export const activatedOnFormatter = ({ value }) => {
  return (
    <>
      {value ? (
        <ClickToCopy text={moment(value).format('DD-MMM-YYYY @ h:mm:ss A')} />
      ) : 'Yet to Activate'}
    </>
  )
}

export const memberStatusFormatter = ({ client, value, userData, user_id, getUserInfo }) => {
  return (
    <>
      <div className={userData.account_status === 'live' ? 'text-success' : 'text-danger'}>
        {Helpers.capitalizeFirstLetter(value) ?? '-'}
      </div>
      {userData.user_type == 'organization' && userData.user_role !== 'owner' ? (
        <UpdateMemberStatus
          couserid={user_id}
          getUserInfo={getUserInfo}
          userData={userData}
          client={client}
        />
      ) : null}
    </>
  )
}

export const accountStatusFormatter = ({ value, userData }) => {
  return (
    <div className={userData.status === 'active' ? 'text-success' : 'text-danger'}>
      {Helpers.capitalizeFirstLetter(value) ?? '-'}
    </div>
  )
}

export const radarFormatter = ({ userData, isChecked, handleToggleChange }) => {
  return (
    <>
      {userData && userData.user_role === 'owner' ? (
        <Toggle
          checked={isChecked}
          onChange={handleToggleChange}
          disabled={
            userData.status === 'active' &&
              userData.account_status === 'live' &&
              userData.activated_on
              ? false
              : true
          }
        />
      ) : (
        '-'
      )}
    </>
  )
}

export const timezoneFormatter = ({ value, userData, user_id, getUserInfo, client }) => {
  return (
    <div className='d-flex'>
      <div>
        {value ?? '-'}
      </div>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='mx-2'>
            <UpdateTimezoneModal
              couserid={user_id}
              getUserInfo={getUserInfo}
              data={userData}
              client={client}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const totalAmountPaidFormatter = ({ additionalData }) => {
  if (additionalData && !_.isEmpty(additionalData.payment_info))
    return Number(additionalData.payment_info.total).toLocaleString()
  else
    return '-'
}


export const lastPurchasedOnFormatter = ({ additionalData }) => {

  if (additionalData && !_.isEmpty(additionalData.payment_info)) {
    return (
      <span>
        <CustomOverlayTrigger
          placement={'top'}
          content={moment(additionalData.payment_info.last_purchased_on).format('DD-MMM-YYYY @ h:mm:ss a')}
        >
          <span>{_.truncate(moment(additionalData.payment_info.last_purchased_on).format('DD-MMM-YYYY @ h:mm:ss a'), { length: 20, omission: '...' })}</span>
        </CustomOverlayTrigger>
      </span>

    );
  } else {
    return '-';
  }
};

export const totalPaymentCountFormatter = ({ additionalData }) => {
  if (additionalData && !_.isEmpty(additionalData.payment_info))
    return Number(additionalData.payment_info.count)
  else
    return '-'
}

// credits and limits tab sections formatters

export const totalRemainingCreditsFormatter = ({ value }) => {
  return (
    <>
      {value !== null
        ? value.toLocaleString()
        : 'Unlimited'}
    </>
  )
}

export const payAsYouGoCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const subscriptionCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const recurCreditsFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const apiRateLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          <div className='d-flex'>
            <div className='mx-2'>
              <UpdateRateLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
            <div>
              <RemoveRateLimitModel
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantEmailVerifyFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const instantEmailFinderFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const dailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )

}

export const remainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex gap-2'>
          <p>{(userData.credits.available_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' && userData.limits.allowed_daily_limit ? (
            <div>
              <UpdateDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export const allowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_limit ? (
        <p>{label}</p>
      ) : null}
      {userData.limits.allowed_daily_limit ? (
        <div className='d-flex'>
          <p>{(userData.limits.allowed_daily_limit ?? 'Unlimited').toLocaleString()}</p>
          {userData.user_type === 'individual' ? (
            <div className='mx-2'>
              <AllowedDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}


export const nextAllocationDate = ({ additionalData }) => {
  const filteredPlans = additionalData?.subscription_details?.filter(
    (item) => item.product_type === 'plan'
  );

  if (filteredPlans && filteredPlans.length > 0 && filteredPlans[0].credits_renewal_date) {
    return (
      <span>
        <CustomOverlayTrigger
          placement={'top'}
          content={<span className="text-wrap">{moment(filteredPlans[0].credits_renewal_date).format('Do MMMM YYYY, h:mm:ss A')}</span>}
        >
          <span>{_.truncate(moment(filteredPlans[0].credits_renewal_date).format('Do MMMM YYYY, h:mm:ss A'), { length: 20, omission: '...' })}</span>
        </CustomOverlayTrigger>
      </span>
    );
  } else {
    return '-';
  }
};

export const planFormatter = ({ additionalData }) => {
  const subscriptionDetails = additionalData?.subscription_details;

  if (subscriptionDetails && !_.isEmpty(subscriptionDetails[0]?.product_name)) {
    const productName = subscriptionDetails[0].product_name;
    const truncatedName = _.truncate(productName, { length: 20, omission: '...' });

    return (
      <span>
        <CustomOverlayTrigger
          placement="top"
          content={<span>{_.startCase(productName)}</span>}
        >
          <span>{truncatedName}</span>
        </CustomOverlayTrigger>
      </span>
    );
  }

  return '-';
};

export const nextBillingDate = ({ additionalData }) => {
  const filteredPlans = additionalData?.subscription_details?.filter(
    (item) => item.product_type === 'plan'
  );

  if (filteredPlans && filteredPlans.length > 0 && filteredPlans[0].end_time) {
    return (
      <span>
        <CustomOverlayTrigger
          placement={'top'}
          content={<span className="text-wrap">{moment(filteredPlans[0].end_time).format('Do MMMM YYYY, h:mm:ss A')}</span>}
        >
          <span>{_.truncate(moment(filteredPlans[0].end_time).format('Do MMMM YYYY, h:mm:ss A'), { length: 20, omission: '...' })}</span>
        </CustomOverlayTrigger>
      </span>

    );
  } else {
    return '-';
  }
};

export const lowCreditThreshold = ({ userData }) => {
  let data = '-'
  let thresholdSign = ''
  if (userData.settings?.low_credit_balance) {
    data = userData.settings?.low_credit_balance.min_threshold
    if (data >= userData.credits?.total) {
      thresholdSign = 'red'
    }
  }
  return (
    userData.user_type === 'organization' && userData.user_role === 'owner' || userData.user_type === 'individual'
      ? <span style={{ color: thresholdSign }}>{data !== '-' ? data.toLocaleString() : '-'}</span>
      : '-'
  )
}

export const billableFormatter = (userData) => {
  if (userData.userData.subscription[0].price.total_price && userData.userData.subscription[0].product_renewal.unit) {
    let billable;
    let amount = userData.userData.subscription[0].price.total_price
    let duration = userData.userData.subscription[0].product_renewal.unit
    let currency = userData.userData.subscription[0].price.currency
    let currencyValue = currency == Constants.CURRENCY.USD.name ? Constants.CURRENCY.USD.symbol : Constants.CURRENCY.INR.symbol
    billable = `${currencyValue}${amount}/${duration}`
    return billable
  } else {
    return '-'
  }
}

// cop formatterrs

export const copDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {userData.user_type === 'individual' ? (
          userData &&
            !userData.limits.allowed_daily_verify_limit
            ? (
              <AddDailyLimitModal
                data={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            ) : null
        ) : null}
        {userData.limits.allowed_daily_verify_limit && userData.user_type === 'individual' ? (
          <RemoveDailyLimit
            data={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
        ) : null}
      </div>
    </>
  )
}

export const copRemainingDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.credits.available_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <UpdateDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const copAllowedDailyLimitFormatter = ({ userData, getUserInfo, client, label }) => {
  return (
    <>
      {userData.limits.allowed_daily_verify_limit ? (
        <p>{label}</p>
      ) : null}
      <div>
        {userData.limits.allowed_daily_verify_limit ? (
          <div className='d-flex'>
            <p>{(userData.limits.allowed_daily_verify_limit ?? 'Unlimited').toLocaleString()}</p>
            {userData.user_type === 'individual' ? (
              <div className='mx-2'>
                <AllowedDailyLimitModal
                  data={userData}
                  getUserInfo={getUserInfo}
                  client={client}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const instantPhoneNumberValidatorFormatter = ({ value, label }) => {
  return (
    <>
      <p>{label}</p>
      <div>
        {value ? value.toLocaleString() : '-'}
      </div>
    </>
  )
}

export const copTotalAmountPaidFormatter = ({ value }) => {
  return (
    <>
      {value ? Number(value).toLocaleString() : '-'}
    </>
  )
}

export const copLastPurchasedOnFormatter = ({ value }) => {
  if (value) {
    return (
      <span>
        <CustomOverlayTrigger
          placement={'top'}
          content={moment(value).format('DD-MMM-YYYY @ h:mm:ss a')}
        >
          <span>{_.truncate(moment(value).format('DD-MMM-YYYY @ h:mm:ss a'), { length: 20, omission: '...' })}</span>
        </CustomOverlayTrigger>
      </span>

    );
  } else {
    return '-';
  }
};


export const copTotalPaymentCountFormatter = ({ value }) => {
  return (
    <>
      {value ? value : '-'}
    </>
  )
}


export const copNextBillingDate = ({ copSubscriptions }) => {
  if (copSubscriptions[0] && !_.isEmpty(copSubscriptions[0].subs_details.paypal.billing_info.next_billing_time)) {
    return (
      <span>
        <CustomOverlayTrigger
          placement={'top'}
          content={moment(copSubscriptions[0].subs_details.paypal.billing_info.next_billing_time).format('Do MMMM YYYY, h:mm:ss A')}
        >
          <span>{_.truncate(moment(copSubscriptions[0].subs_details.paypal.billing_info.next_billing_time).format('Do MMMM YYYY, h:mm:ss A'), { length: 20, omission: '...' })}</span>
        </CustomOverlayTrigger>
      </span>
    );
  } else {
    return '-';
  }
};

export const coPlanFormatter = ({ copSubscriptions }) => {

  if (copSubscriptions[0]) {
    const productName = copSubscriptions[0].plan_name;
    const truncatedName = _.truncate(productName, { length: 25, omission: '...' });

    return (
      <span>
        <CustomOverlayTrigger
          placement="top"
          content={<span>{_.startCase(productName)}</span>}
        >
          <span>{truncatedName}</span>
        </CustomOverlayTrigger>
      </span>
    );
  }
  return '-';
};

// receipt table formatter

export const receiptCreditFormatter = ({ value }) => {
  return (
    <>
      {value ? value.toLocaleString() : '-'}
    </>
  )
}

export const coReceiptBillingFormatter = ({ rowData, value }) => {
  let billing = '-'

  if (rowData.type === 'paid')
    if (value === null)
      billing = 'One-Time'
    else
      billing = `${value.count} ${value.unit}`

  return (
    <>{billing}</>
  )
}

export const receiptBillingFormatter = ({ value }) => {
  return (
    <>
      {value ?
        (value === 30 ? 'Monthly' : 'Annually') : '-'}
    </>
  )
}

export const receiptPaymentFormatter = ({ value }) => {
  return (
    <>
      {value}
    </>
  )
}

export const receiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const receiptAmountFormatter = ({ rowData, value }) => {
  let formattedAmount = value.toLocaleString(Constants.CURRENCY.USD.locale)
  let val = formattedAmount
  let toolTipContent = <></>

  if (rowData.type === 'paid') {
    let basePrice = rowData.amount_details.base_price.toLocaleString(Constants.CURRENCY.USD.locale)

    if (rowData.amount_details.currency !== Constants.CURRENCY.USD.name) {
      let formattedCurrencyAmount = rowData.amount_details.total_price
        .toLocaleString(Constants.CURRENCY[rowData.amount_details.currency].locale)

      val = formattedCurrencyAmount

      toolTipContent = (
        <div className='d-flex flex-column text-start'>
          <span>
            <small>{'Actual price: '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{basePrice}</span>
          </span>
          <span>
            <small>{'USD equivalent (Incl. tax): '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{formattedAmount}</span>
          </span>
        </div>
      )
    } else {

      toolTipContent = (
        <div className='d-flex flex-column text-start'>
          <span>
            <small>{'Actual price: '}</small>&nbsp;
            <span>{Constants.CURRENCY.USD.symbol}{basePrice}</span>
          </span>
        </div>
      )
    }

    return (
      <>
        <CustomOverlayTrigger
          content={toolTipContent}
          placement={'bottom'}
        >
          <div>
            {rowData.amount_details.currency_symbol}
            {val}
          </div>
        </CustomOverlayTrigger>
      </>
    )

  }


  return (
    <>{val}</>
  )
}

export const receiptCouponFormatter = ({ rowData, value }) => {
  let coupon = '-'

  if (value && value.coupon) {
    let couponVal = 0
    let toolTipContent = <></>

    if (value.coupon_type === 'discount') {
      let formattedAmount = value.discount_price.toLocaleString(Constants.CURRENCY.USD.locale)
      let formattedCurrencyAmount
      couponVal = `${Constants.CURRENCY.USD.symbol} ${formattedAmount}`

      if (rowData.amount_details.currency !== Constants.CURRENCY.USD.name) {
        formattedCurrencyAmount = rowData.amount_details.discount_price
          .toLocaleString(Constants.CURRENCY[rowData.amount_details.currency].locale)

        couponVal = `${Constants.CURRENCY[rowData.amount_details.currency].symbol} ${formattedCurrencyAmount}`
        toolTipContent = (
          <div className='d-flex flex-column text-start'>
            <span>
              <small>{'USD Equivalent: '}</small>
              <span>{Constants.CURRENCY.USD.symbol}{formattedAmount}</span>
            </span>
          </div>
        )
      }
    }

    if (value.coupon_type === 'bonus') {
      couponVal = `${value.bonus_value.toLocaleString('en-US')} Credits`
    }

    coupon =
      <div className='d-flex flex-column'>
        <Link to={'/clearout/coupons/' + value.coupon}>
          {value.coupon}
        </Link>
        <span className='text-muted text-uppercase'>
          <small>
            <CustomOverlayTrigger
              content={toolTipContent}
              placement={'bottom'}
            >
              <div>
                {couponVal}
              </div>
            </CustomOverlayTrigger>
          </small>
        </span>
      </div>
  }

  return (
    <>
      {coupon}
    </>
  )
}

export const receiptPurchasedFormatter = ({ value }) => {
  return (
    <small>
      {moment(value).format('DD MMM YY, hh:mm:ss A z')}
    </small>
  )
}

export const receiptTypeFormatter = ({ value }) => {
  return (
    <>
      {Helpers.capitalizeFirstLetter(value)}
    </>
  )
}

export const receiptDescriptionFormatter = ({ rowData, value, client }) => {
  let val = '-'
  if (value)
    val = value

  if (rowData.type === 'paid')
    val = (
      <div className='d-flex flex-column'>
        {client === 'clearout' ? (
          <span>{rowData.product_details.display_name}</span>
        ) : <span>{rowData.description}</span>}
        {client === 'clearout' ? (
          <span className='text-muted text-uppercase'>
            <small>
              Payment Gateway: {_.startCase(rowData.account_name)}
            </small>
          </span>
        ) : null}
      </div>
    )

  return (<>{val}</>)
}

export const receiptPlanIdFormatter = ({ value }) => {
  return (
    <>
      <b><small>{value ? value : '-'}</small></b>
    </>
  )
}

export const receiptPayeeInfoFormatter = ({ value, rowData }) => {
  const getPayeeInfo = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.payer.name.given_name + ' ' + invoice.payment_details.result.payer.name.surname}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.payer.email_address}&nbsp; &nbsp;
            <ClipboardCopy textToCopy={invoice.payment_details.result.payer.email_address} />
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeInfo(rowData) : '-'}
    </>
  )
}

export const coReceiptPayeeInfoFormatter = ({ value }) => {
  let payeeInfo = '-'

  if (value && value.gateway && value.gateway.email_address) {
    payeeInfo = (
      <>
        <span>{value.gateway.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.email_address} />
        </span>
        <span className='d-flex align-items-baseline'>
          {value.gateway.user_id}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.gateway.user_id} />
        </span>
      </>
    )
  } else if (value && value.co && value.co.email_address) {
    payeeInfo = (
      <>
        <span>{value.co.name}</span>
        <span className='d-flex align-items-baseline'>
          {value.co.email_address}&nbsp; &nbsp;
          <ClipboardCopy textToCopy={value.co.email_address} />
        </span>
      </>
    )
  }

  return (
    <>{payeeInfo}</>
  )
}

export const coReceiptPayeeAddressFormatter = ({ value }) => {
  let payeeAddress = '-'

  if (value && value.gateway && value.gateway.billing_address.country_code) {
    payeeAddress = (
      <>
        <span>
          {
            value.gateway.billing_address.address_line_1
              ? value.gateway.billing_address.address_line_1 + ', '
              : null
          }
          {
            value.gateway.billing_address.address_line_2
              ? value.gateway.billing_address.address_line_2 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.admin_area_2
              ? value.gateway.billing_address.admin_area_2 + ', '
              : null
          }
          {
            value.gateway.billing_address.admin_area_1
              ? value.gateway.billing_address.admin_area_1 + ','
              : null
          }
        </span>
        <span>
          {
            value.gateway.billing_address.postal_code
              ? value.gateway.billing_address.postal_code + ' - '
              : null
          }
          {
            value.gateway.billing_address.country_code
              ? value.gateway.billing_address.country_code
              : null
          }
        </span>
      </>
    )
  } else if (_.get(value, 'co.billing_address.country.value', null)) {
    payeeAddress = (
      <>
        <span>
          {
            value.co.billing_address.line1
              ? value.co.billing_address.line1 + ', '
              : null
          }
          {
            value.co.billing_address.line2
              ? value.co.billing_address.line2 + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.city
              ? value.co.billing_address.city + ', '
              : null
          }
          {
            value.co.billing_address.state
              ? value.co.billing_address.state + ','
              : null
          }
        </span>
        <span>
          {
            value.co.billing_address.postal_code
              ? value.co.billing_address.postal_code + ' - '
              : null
          }
          {
            value.co.billing_address.country.value
              ? value.co.billing_address.country.value
              : null
          }
        </span>
      </>
    )
  }

  return (
    <div className='d-flex flex-column'>
      {payeeAddress}
    </div>
  )
}

export const receiptPayeeAddressFormatter = ({ value, rowData }) => {
  const getPayeeAddress = (invoice) => {
    return (
      invoice.payment_details?.result ? (
        <>
          <span>{invoice.payment_details.result.purchase_units[0].shipping.address.address_line_1}</span>
          <span className='d-flex align-items-baseline'>
            {invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_1 + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.admin_area_2}
          </span>
          <span>
            {invoice.payment_details.result.purchase_units[0].shipping.address.country_code + ' ' +
              invoice.payment_details.result.purchase_units[0].shipping.address.postal_code}
          </span>
        </>
      ) : '-'
    );
  };

  return (
    <>
      {value ? getPayeeAddress(rowData) : '-'}
    </>
  )
}

export const invoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, rowData, client }) => {
  if (rowData.type !== 'paid')
    return null

  const apiInvoicePath = IndividualUserConstants.CO_INVOICE_DOWNLOAD_ENDPOINT(client)
  const apiReceiptPath = IndividualUserConstants.CO_RECEIPT_DOWNLOAD_ENDPOINT(client)
  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          APP_API_URL +
          apiInvoicePath +
          'invoice_id=' + value +
          '&auth_token=' + jwtToken +
          '&co_user_id=' + userData.user_id
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          APP_API_URL + apiReceiptPath +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&co_user_id=' + userData.user_id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}

//cop receipt table formatter
export const copReceiptUnitPriceFormatter = ({ value, rowData }) => {
  return (
    <>
      {rowData.payment_type === 'subscription' ? '-' : value}
    </>
  )
}

export const copReceiptAmountFormatter = ({ rowData }) => {
  const getCopAmount = (invoice) => {
    let amount, convertedAmount

    amount = invoice.amount
    if (invoice && invoice.currency_exchange_info && Object.keys(invoice.currency_exchange_info).length) {
      convertedAmount = (Math.floor(invoice.currency_exchange_info.final_price * 100) / 100)
      convertedAmount = `${invoice.currency_exchange_info.currency_symbol}${convertedAmount.toLocaleString('en-IN')}`
    }
    return (
      <td>
        {Number(amount).toLocaleString()} < br />
        {convertedAmount ? convertedAmount : null}
      </td>
    )
  }
  return (
    <>
      {getCopAmount(rowData)}
    </>
  )
}

export const copReceiptCouponFormatter = ({ rowData }) => {
  const getCopCoupon = (invoice) => {
    let coupon = '-'

    if (invoice && invoice.discount) {
      coupon =
        <>
          <Link to={'/clearoutphone/coupons/' + invoice.discount.coupon}>
            {invoice.discount.coupon}
          </Link>
          &nbsp;(<small>${invoice.discount.discount_price}</small>)
        </>
    }
    return <td>{coupon}</td>
  }

  return (
    <>
      {getCopCoupon(rowData)}
    </>
  )
}

//need to discuss
export const copInvoiceAndReceiptDownloadFormatter = ({ value, jwtToken, userData, userName, id }) => {

  return (
    <div className={classNames('d-flex gap-2', { 'flex-column': isMobile })}>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_INVOICE_DOWNLOAD_ENDPOINT +
          'invoice_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Invoice</span>
            :
            <i
              className="fa fa-download"
              title="Download Invoice"
            >
            </i>
        }
      </a>
      <a
        href={
          CLEAROUT_PHONE_BASE_URL + IndividualUserConstants.COP_RECEIPT_DOWNLOAD_ENDPOINT +
          'receipt_id=' + value + '&auth_token=' + jwtToken + '&cop_user_id=' + userData.user_id +
          '&client_secret=' + Constants.CLIENT_SECRET + '&crm_user_data[name]=' + userName + '&crm_user_data[user_id]=' + id
        }
        target="blank"
      >
        {
          isMobile
            ? <span>Receipt</span>
            :
            <i
              className="fa fa-download"
              title="Download Receipt"
            >
            </i>
        }
      </a>
    </div>
  )
}
















