import React, { useState } from 'react';
import { Modal, Button, Form, } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash'

import showToastNotification from '../widgets/toastify';
import * as Constants from '../../helpers/constants'
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  reason: 'user requested',
  isApiCallInProgress: false
}

function ToggleRadarModel({ show, onHide, co_user_id, name, getUserInfo, client }) {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const handleToggleRadarApiCall = (body) => {
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiHelper(client, 'RADAR').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'failed',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: 'Radar settings updated successfully',
            isSuccess: true,
          });
          onHide()
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };


  const handleSaveChanges = () => {
    let requestBody = {
      co_user_id: co_user_id,
      user_id: id,
      reason: state.reason,
      radarStatus: false
    };

    let body = _.pick(requestBody, ApiHelper(client, 'RADAR').API_BODY)

    handleToggleRadarApiCall(body)
  };

  const handleClose = () => {
    getUserInfo();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>Are you sure you want to turn off radar  for {name}&apos;s account </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className='row'>
            <Form.Group
              className='d-flex'
            >
              <div className='col-md-3'>
                <Form.Label className='w-100 py-2'>Enter Reason:</Form.Label>
              </div>
              <div className='col-md-9'>
                <Form.Control
                  type="text"
                  value={state.reason}
                  onChange={handleReasonChange}
                />
              </div>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSaveChanges}
          disabled={state.isApiCallInProgress}
        >
          Turn Off Radar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ToggleRadarModel;
