import moment from 'moment'
export const COCRM_USER = 'user'

export const CO_API = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const PAGE_SIZE = {
  values: [50, 100, 150, 200]
}
export const REGEX = {
  EMAIL_SYNTAX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const API_OPTIONS = [
  { value: 'Instant Email Verify', label: 'Instant Email Verify' },
  { value: 'Instant Email Finder', label: 'Instant Email Finder' },
  { value: 'All', label: 'All API\'s' }
];

// export const API_RATELIMIT_SERVICES = {
//   INSTANT_EMAIL_VERIFY_LIMIT: 'instant_email_verify_limit',
//   INSTANT_EMAIL_FINDER_LIMIT: 'instant_email_finder_limit',
// }
export const ACCOUNT_DELETE_REASONS = {
  'data_privacy_concern': 'Data Privacy Concern',
  'alternative_found': 'Alternative Found',
  'temporary_suspension_required': 'Temporary Suspension Required',
  'service_no_longer_needed': 'Service No Longer Needed',
  'none_of_the_above': 'None of the Above'
}

export const SUBSCRIPTION_FILTERS = {
  status: [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'cancelled',
      label: 'Cancelled'
    }
  ],
  planType: [
    {
      value: 'yearly',
      label: 'Annually'
    },
    {
      value: 'monthly',
      label: 'Monthly'
    }
  ],
  userType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'subscriber',
      label: 'Subscribed'
    },
    {
      value: 'paid',
      label: 'Paid'
    }
  ],
  userStatusType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
  ],
  orgUserStatusType: [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
    {
      value: 'new',
      label: 'Yet to Activate'
    },
    {
      value: 'removed',
      label: 'Removed'
    }
  ],
  role: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'owner',
      label: 'Owner'
    },
    {
      value: 'admin',
      label: 'Manager'
    },
    {
      value: 'analyst',
      label: 'Executive'
    },
  ]
}

//linechart
export const RESULTS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'percentage'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'percentage'
  },
  catch_all: {
    display_name: 'Catch All',
    type: 'percentage'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'percentage'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'percentage'
  },
  disposable: {
    display_name: 'Disposable',
    type: 'percentage'
  },
  role_account: {
    display_name: 'Role Account',
    type: 'percentage'
  },
  free_account: {
    display_name: 'Free Account',
    type: 'percentage'
  },
  profile: {
    display_name: 'Profile Account',
    type: 'percentage'
  },
  billable: {
    display_name: 'Billable',
    type: 'value'
  },
  deliverability_score: {
    display_name: 'Deliverability Score',
    type: 'value'
  },
  syntax_error: {
    display_name: 'Syntax Error',
    type: 'percentage'
  },
  billable_credits: {
    display_name: 'Credits Used (Billable)',
    type: 'value'
  },
  safe_to_send: {
    display_name: 'Safe To Send',
    type: 'percentage'
  },
  gibberish: {
    display_name: 'Gibberish Account',
    type: 'percentage'
  },
  found: {
    display_name: 'Emails Found',
    type: 'percentage'
  },
  role: {
    display_name: 'Role',
    type: 'percentage'
  },
  business: {
    display_name: 'Business',
    type: 'percentage'
  },
  confidence_level: {
    display_name: 'Confidence Level',
    type: 'text'
  },
  total_phones: {
    display_name: 'Total Phones',
    type: 'value'
  },
  mobile_phones: {
    display_name: 'Mobile Phones',
    type: 'percentage'
  },
  fixed_phones: {
    display_name: 'Fixed Phones',
    type: 'percentage'
  },
  others_phones: {
    display_name: 'Other Phones',
    type: 'percentage'
  },
  unknown_phones: {
    display_name: 'Unknown Phones',
    type: 'percentage'
  },
  valid_phones: {
    display_name: 'Valid Phones',
    type: 'percentage'
  },
  invalid_phones: {
    display_name: 'Invalid Phones',
    type: 'percentage'
  },
  total_emails: {
    display_name: 'Total Emails',
    type: 'value'
  },
  personal_emails: {
    display_name: 'Personal Emails',
    type: 'percentage'
  },
  work_emails: {
    display_name: 'Work Emails',
    type: 'percentage'
  },
  valid_emails: {
    display_name: 'Valid Emails',
    type: 'percentage'
  },
  catch_all_emails: {
    display_name: 'Catch All Emails',
    type: 'percentage'
  },
  invalid_emails: {
    display_name: 'Invalid Emails',
    type: 'percentage'
  },
  unknown_emails: {
    display_name: 'Unknown Emails',
    type: 'percentage'
  },
};

export const SERVICE_BASED_REPORT_FIELDS = {
  verify: {
    total: {
      display_name: 'Total',
      type: 'value'
    },
    valid: {
      display_name: 'Valid',
      type: 'percentage'
    },
    invalid: {
      display_name: 'Invalid',
      type: 'percentage'
    },
    catch_all: {
      display_name: 'Catch All',
      type: 'percentage'
    },
    unknown: {
      display_name: 'Unknown',
      type: 'percentage'
    },
    duplicate: {
      display_name: 'Duplicate',
      type: 'percentage'
    },
    disposable: {
      display_name: 'Disposable',
      type: 'percentage'
    },
    role_account: {
      display_name: 'Role Account',
      type: 'percentage'
    },
    free_account: {
      display_name: 'Free Account',
      type: 'percentage'
    },
    profile: {
      display_name: 'Profile Account',
      type: 'percentage'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    },
    deliverability_score: {
      display_name: 'Deliverability Score',
      type: 'value'
    },
    syntax_error: {
      display_name: 'Syntax Error',
      type: 'percentage'
    },
    billable_credits: {
      display_name: 'Credits Used (Billable)',
      type: 'value'
    },
    safe_to_send: {
      display_name: 'Safe To Send',
      type: 'percentage'
    },
    business: {
      display_name: 'Business Emails',
      type: 'percentage'
    },
    role: {
      display_name: 'Role Based Emails',
      type: 'percentage'
    }
  },
  finder: {
    total: {
      display_name: 'Total Request',
      type: 'value'
    },
    found: {
      display_name: 'Email Found',
      type: 'value'
    },
    not_found: {
      display_name: 'Email Not Found',
      type: 'value'
    },
    business: {
      display_name: 'Business',
      type: 'value'
    },
    role: {
      display_name: 'Role',
      type: 'value'
    },
    confidence_score: {
      display_name: 'Confidence Score',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  prospect: {
    total_billable_email_contact: {
      display_name: 'Email Contacts',
      type: 'value'
    },
    total_billable_phone_contact: {
      display_name: 'Phone Contacts',
      type: 'value'
    },
    total_billable_lead: {
      display_name: 'Total Prospects',
      type: 'value'
    },
    total_credits_charged: {
      display_name: 'Total Credits',
      type: 'value'
    },
    email: {
      display_name: 'Email Lead',
      type: 'value'
    },
    phone: {
      display_name: 'Phone Lead',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  overall: {
    ev_billable: {
      display_name: 'Email Verifier',
      type: 'value'
    },
    ef_billable: {
      display_name: 'Email Finder',
      type: 'value'
    },
    prospect_billable: {
      display_name: 'Prospect',
      type: 'value'
    }
  }
}
export const GRAPH_COLORS = ['#c6e377', '#f16f6f', '#75cac3', '#c0c0c0', '#970690']

export const GRAPH_AREA_COLORS = ['rgba(199, 227, 120, 0.20)', 'rgba(241, 111, 111, 0.20)', 'rgba(118, 203, 196, 0.20)', 'rgba(191, 191, 191, 0.20)', 'rgba(235, 193, 233, 0.2)']
/////////////////////
export const DATE_RANGE_PRESETS = {
  'Today': 'ps_today',
  'Yesterday': 'ps_yesterday',
  'This week (Mon - Today)': 'ps_this_week_mon_today',
  'Last 7 days (Including Today)': 'ps_last_7_days_including_today',
  'Last week (Mon - Sun)': 'ps_last_week_mon_sun',
  'This Month': 'ps_this_month',
  'Last Month': 'ps_last_month',
  'This Year': 'ps_this_year',
  'Last Year': 'ps_last_year'
};

export const RANGES = {
  'All': [moment(), moment()],
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This week (Mon - Today)': [moment().startOf('isoweek'), moment()],
  'Last 7 days (Including Today)': [moment().subtract(6, 'days'), moment()],
  'Last week (Mon - Sun)': [moment().subtract(1, 'week').startOf('isoweek'), moment().subtract(1, 'week').endOf('isoweek')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment()],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
}

export const DATERANGE_PRESETS = {
  date_range_presets: [
    'ps_today',
    'ps_yesterday',
    'ps_this_week_mon_today',
    'ps_last_7_days_including_today',
    'ps_last_week_mon_sun',
    'ps_this_month',
    'ps_last_month',
    'ps_this_year',
    'ps_last_year'
  ],
  date_range_presets_definitions: {
    ps_today: {
      display_name: 'Today',
      value: 'ps_today'
    },
    ps_yesterday: {
      display_name: 'Yesterday',
      value: 'ps_yesterday'
    },
    ps_last_7_days_including_today: {
      display_name: 'Last 7 days (Including Today)',
      value: 'ps_last_7_days_including_today'
    },
    ps_this_week_mon_today: {
      display_name: 'This week (Mon - Today)',
      value: 'ps_this_week_mon_today'
    },
    ps_last_week_mon_sun: {
      display_name: 'Last week (Mon - Sun)',
      value: 'ps_last_week_mon_sun'
    },
    ps_this_month: {
      display_name: 'This Month',
      value: 'ps_this_month'
    },
    ps_last_month: {
      display_name: 'Last Month',
      value: 'ps_last_month'
    },
    ps_this_year: {
      display_name: 'This Year',
      value: 'ps_this_year'
    },
    ps_last_year: {
      display_name: 'Last Year',
      value: 'ps_last_year'
    },
    ps_last_24_hours: {
      display_name: 'Last 24 Hrs',
      value: 'ps_last_24_hours'
    }
  }
}

export const CLIENT_SECRET = '5b98682fe6339bf7706a3bf335b1568089d8a507c9a6a95ef60a3739b862a961'

export const CLIENTS = {
  CLEAROUT: {
    NAME: 'clearout',
    SHORT_NAME: 'co',
    INITIALS: 'CO',
    DISPLAY_NAME: 'Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Users',
    APP_URL: CLEAROUT_APP_URL
  },
  CLEAROUTPHONE: {
    NAME: 'clearoutphone',
    SHORT_NAME: 'cop',
    INITIALS: 'COP',
    DISPLAY_NAME: 'ClearoutPhone',
    SERVICE: 'clearoutphone',
    DOWNLOAD_NAME: 'Download ClearoutPhone Users',
    APP_URL: COP_APP_URL
  },
  SPOKESLY_CLEAROUT: {
    NAME: 'spokesly_clearout',
    SHORT_NAME: 'spokesly',
    INITIALS: 'SPO',
    DISPLAY_NAME: 'Spokesly\'s Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Spokesly Users',
    APP_URL: SPOKESLY_CLEAROUT_APP_URL
  },
  EU_CLEAROUT: {
    NAME: 'eu_clearout',
    SHORT_NAME: 'co_eu',
    INITIALS: 'EU',
    DISPLAY_NAME: 'EU\'s Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout EU Users',
    APP_URL: EU_CLEAROUT_APP_URL
  },
  PEPIPOST_CLEAROUT: {
    NAME: 'pepipost_clearout',
    SHORT_NAME: 'pepipost',
    INITIALS: 'PEP',
    DISPLAY_NAME: 'Pepipost\'s Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Pepipost Users',
    APP_URL: PEPIPOST_CLEAROUT_APP_URL
  },
  CAMS_CLEAROUT: {
    NAME: 'cams_clearout',
    SHORT_NAME: 'co_cams',
    INITIALS: 'CAM',
    DISPLAY_NAME: 'Cam\'s Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Cams Users',
    APP_URL: CAMS_CLEAROUT_APP_URL
  },
}

export const SERVICE_TYPES = {
  EMAIL_VERIFIER: {
    NAME: 'email_verifier',
    VALUE: 'EMAIL_VERIFIER',
    DISPLAY_NAME: 'Email Verifier',
    HAS_SETTINGS: true,
    GET_LIST_API: 'EV_LIST',
    DOWNLOAD_INPUT_FILE: 'DOWNLOAD_EV_LIST_INPUT_FILE'
  },
  EMAIL_FINDER: {
    NAME: 'email_finder',
    VALUE: 'EMAIL_FINDER',
    DISPLAY_NAME: 'Email Finder',
    HAS_SETTINGS: true,
    GET_LIST_API: 'EF_LIST',
    DOWNLOAD_INPUT_FILE: 'DOWNLOAD_EF_LIST_INPUT_FILE'
  },
  PROSPECT: {
    NAME: 'prospect',
    VALUE: 'PROSPECT',
    DISPLAY_NAME: 'Prospect',
    HAS_SETTINGS: true,
    GET_LIST_API: 'PROSPECT_LIST',
    DOWNLOAD_INPUT_FILE: 'DOWNLOAD_PROSPECT_LIST_INPUT_FILE'
  },
  PHONE_NUMBER_VERIFIER: {
    NAME: 'phone_number_verifier',
    VALUE: 'PHONE_NUMBER_VERIFIER',
    DISPLAY_NAME: 'Phone Number Verifier',
    HAS_SETTINGS: false,
    GET_LIST_API: 'GET_COP_LIST',
    DOWNLOAD_INPUT_FILE: 'DOWNLOAD_COP_LIST_INPUT_FILE'
  },
};

export const SERVICE_FILTERS = {
  email_verifier: ['selectedTypeFilter', 'selectedDateRange', 'verifiedListFilter'],
  email_finder: ['selectedDateRange', 'processedListFilter'],
  prospect: ['prospectTypeFilter', 'selectedDateRange', 'enrichedStatusFilter', 'enrichedOnFilter'],
  phone_number_verifier: ['verifiedListFilter', 'selectedDateRange', 'selectedTypeFilter'],
};

export const BUTTON_TEXT_FOR_SERVICE_TYPES = {
  email_verifier: 'Verify',
  email_finder: 'Find Emails',
  prospect: 'Enrich',
  phone_number_verifier: 'Verify'
}

export const API_RATE_LIMIT_OPTIONS = {
  [CLIENTS.CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.CLEAROUTPHONE.NAME]: [
    { value: 'instant_phonenumber_validate_limit', label: 'Instant Phone Number Validator' },
    // { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.EU_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.CAMS_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
}

export const BREAD_CRUMB_OPTIONS = {
  [CLIENTS.CLEAROUT.NAME]: [
    { label: 'Clearout Users', href: '/clearout/users', active: false },
    { label: 'Clearout Individual User Info', href: '/clearout/individual_user_info', active: true },
  ],
  [CLIENTS.CLEAROUTPHONE.NAME]: [
    { label: 'ClearoutPhone Users', href: '/clearoutphone/users', active: false },
    { label: 'ClearoutPhone Individual User Info', href: '/clearoutphone/individual_user_info', active: true },
  ],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: [
    { label: 'Spokesly Clearout Users', href: '/spokesly_clearout/users', active: false },
    { label: 'Spokesly Clearout Individual User Info', href: '/spokesly_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.EU_CLEAROUT.NAME]: [
    { label: 'Clearout EU Users', href: '/eu_clearout/users', active: false },
    { label: 'Clearout EU Individual User Info', href: '/eu_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: [
    { label: 'Pepipost Clearout Users', href: '/pepipost_clearout/users', active: false },
    { label: 'Pepipost Clearout Individual User Info', href: '/pepipost_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.CAMS_CLEAROUT.NAME]: [
    { label: 'Cams Clearout Users', href: '/cams_clearout/users', active: false },
    { label: 'Cams Clearout Individual User Info', href: '/cams_clearout/individual_user_info', active: true },
  ],
  clearout_org: [
    { label: 'Clearout Organization Users', href: '/clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/clearout/organization_info', active: true },
  ],
  spokesly_clearout_org: [
    { label: 'Clearout Organization Users', href: '/spokesly_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/spokesly_clearout/organization_info', active: true },
  ],
  eu_clearout_org: [
    { label: 'Clearout Organization Users', href: '/eu_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/eu_clearout/organization_info', active: true },
  ],
  ep_clearout_org: [
    { label: 'Clearout Organization Users', href: '/ep_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/ep_clearout/organization_info', active: true },
  ],
  pepipost_clearout_org: [
    { label: 'Clearout Organization Users', href: '/pepipost_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/pepipost_clearout/organization_info', active: true },
  ],
  cams_clearout_org: [
    { label: 'Clearout Organization Users', href: '/cams_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/cams_clearout/organization_info', active: true },
  ],
};

export const CURRENCY = {
  USD: {
    flag: require('../../src/static/svg/us_flag.svg'),
    symbol: '$',
    name: 'USD',
    locale: 'en-US',
    value: 'usd'
  },
  INR: {
    flag: require('../../src/static/svg/in_flag.svg'),
    symbol: '₹',
    name: 'INR',
    locale: 'en-IN',
    value: 'inr'
  }
}

export const DISPLAY_USER_ROLES = {
  admin: {
    value: 'admin',
    display_name: 'Manager',
    profile_tabs: ['credits_limits', 'analytics', 'user_activities']
  },
  analyst: {
    value: 'analyst',
    display_name: 'Executive',
    profile_tabs: ['credits_limits', 'analytics', 'user_activities']
  },
  owner: {
    value: 'owner',
    display_name: 'Owner',
    profile_tabs: ['credits_limits', 'plans', 'subscription_history', 'invoices_receipts', 'analytics', 'user_activities']
  },
}

export const COUPON_TYPE = {
  standard: {
    display: 'Standard',
    value: 'standard'
  },
  discount: {
    display: 'Discount',
    value: 'discount'
  },
  bonus: {
    display: 'Bonus',
    value: 'bonus'
  }
};

export const COUPON_MIN_VALUE_CREDITS = 5000;
export const COUPON_MAX_VALUE_CREDITS = 10000;
export const COUPON_CREDITS_DEFAULT_VALUE = 100000;
export const COUPON_MAX_VALUE_FIXED = 10000;
export const COUPON_REDEEM_COUNT = 100000;
export const COUPON_MIN_FOR_FIXED = 21;
export const COUPON_SLAB_UNIT_PRICE = 0.001;
export const COUPON_SLAB_MAX_UNIT_PRICE = 0.005;
export const COUPON_DISCOUNT_FIXED_DEFAULT = 10;
export const COUPON_MAX_LENGTH = 15;
export const COUPON_MIN_LENGTH = 4;
export const MIN_VAL = 3000;
export const MAX_VAL = 5000000;
export const DURATION_VALUE = 1;


export const PAYMENT_ACCOUNTS = {
  PAYPAL: [
    { label: 'PayPal IND', value: 'paypal::india' },
    { label: 'PayPal US', value: 'paypal::us' },
  ],
  STRIPE: [
    { label: 'Stripe IND', value: 'stripe::india' },
    { label: 'Stripe US', value: 'stripe::us' },
  ],
}


export const COUPON_CONSTANTS = {
  NAME: 'Coupon name',
  COUPON_ID: 'Coupon id will used by customers for applying discount',
  REDEEM_COUNT: 'Number of times the customer can redeem the coupon',
  COUPON_TYPE: 'Coupon type : standard or discount or bonus coupon',
  DISCOUNT_TYPE: 'Select discount type',
  SLAB_MIN: 'Minimum credits to be purchased by user for applying the coupon',
  SLAB_MAX: 'Maximum credits to be purchased by user for applying the coupon',
  SLAB_VAL: 'Unit price for each credit',
  FIXED_MIN: 'Minimum billing amount for coupon to be applied',
  FIXED_MAX: 'Maximum billing amount for coupon to be applied',
  FIXED_VAL: 'Flat value which will be subtracted from the billing price',
  PERC_MIN: 'Minimum billing amount for the coupon to be applied',
  PERC_MAX: 'Maximum billing amount for the coupon to be applied',
  PERC_VAL: 'Percentage of amount to be decreased from billing price',
  CREDITS: 'Credits for assigning the user',
  USER_EMAIL: 'Select the user for this coupon',
  AFFILIATE: 'Enter the affiliate name',
  DAILY_VERIFY_LIMIT: 'Enter the daily limit for verification',
  DURATION_IN_MONTH: 'Number of months credits to be assigned to the user',
};


export const COUPONS_DROPDOWNS = {
  CLEAROUT: {
    discount: 'Discount',
    standard: 'Standard',
    bonus: 'Bonus'
  },
  CLEAROUTPHONE: {
    discount: 'Discount',
    standard: 'Standard',
  },
}
export const ACTION_TYPES = {
  domain_blocked: 'Domain Blocked',
  domain_unblocked: 'Domain Unblocked',
  email_denied: 'Email Denied',
  email_whitelisted: 'Email Whitelisted',
  email_delisted: 'Email Delisted',
  ip_blocked: 'IP Blocked',
  ip_unblocked: 'IP Unblocked',
  country_blocked: 'Country Blocked',
  matomo_blocked: 'Matomo Blocked',
  captcha_blocked: 'Captcha Blocked'
}

export const ACTIONS = {
  signup_abuse: 'Signup Abuse',
  signup_activation_abuse: 'Signup Activation Abuse',
  email_edit_abuse: 'Email Edit Abuse'
}

export const RENEWAL_UNITS = {
  month: {
    short_name: 'mo',
    name: 'Monthly',
    patterns: [
      {
        unit: 'month',
        count: 1
      },
      {
        unit: 'day',
        count: 30
      }
    ]
  },
  year: {
    short_name: 'yr',
    name: 'Yearly',
    patterns: [
      {
        unit: 'year',
        count: 1
      },
      {
        unit: 'day',
        count: 360
      },
      {
        unit: 'day',
        count: 365
      }
    ]
  },
  day: {
    short_name: 'day',
    name: 'Daily',
    patterns: [
      {
        unit: 'day',
        count: 1
      }
    ]
  },
  week: {
    short_name: 'week',
    name: 'Weekly',
    patterns: [
      {
        unit: 'week',
        count: 1
      }
    ]
  }
}

export const PAGINATION_FILTERS = {
  '10': {
    display_name: '10',
    action_value: 10
  },
  '25': {
    display_name: '25',
    action_value: 25
  },
  '50': {
    display_name: '50',
    action_value: 50
  },
  '100': {
    display_name: '100',
    action_value: 100
  }
};

export const SF_API = {
  Success: 'success',
  Failure: 'failed',
  Header: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const LIST_ACTIONS = {
  sync: {
    value: 'sync',
    display: 'Sync',
    progress: 'Syncing',
    queued: ' Queued',
    history: 'synced'
  },
  verify: {
    value: 'verify',
    display: 'Verify',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  pnv: {
    value: 'pnv',
    display: 'PNV',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  analyse: {
    value: 'analyse',
    display: 'Analyse',
    progress: 'Analyzing',
    queued: ' Queued',
    history: 'analysed'
  },
  export: {
    value: 'export',
    display: 'Export',
    progress: 'Exporting',
    queued: ' Queued',
    history: 'exported'
  },
  failed: {
    value: 'failed',
    display: 'Failed',
    progress: 'Failing',
    queued: ' Queued',
    history: 'failed'
  },
  find_emails: {
    value: 'email_finder',
    display: 'Finding Emails',
    progress: 'Finding Emails',
    queued: ' Queued',
    history: 'Email Found'
  },
  enrich: {
    progress: 'Enriching !!!'
  }
};

export const LIST_TYPES = {
  mailchimp: {
    value: 'mailchimp',
    label: 'Mailchimp'
  },
  apollo: {
    value: 'apollo',
    label: 'Apollo'
  },
  active_campaign: {
    value: 'active_campaign',
    label: 'ActiveCampaign'
  },
  moosend: {
    value: 'moosend',
    label: 'Moosend'
  },
  mailerlite: {
    value: 'mailerlite',
    label: 'MailerLite'
  },
  sendgrid: {
    value: 'sendgrid',
    label: 'Sendgrid'
  },
  automizy: {
    value: 'automizy',
    label: 'Automizy'
  },
  clevertap: {
    value: 'clevertap',
    label: 'CleverTap'
  },
  hubspot: {
    value: 'hubspot',
    label: 'Hubspot'
  },
  lemlist: {
    value: 'lemlist',
    label: 'Lemlist'
  },
  go_high_level: {
    value: 'go_high_level',
    label: 'GoHighLevel'
  },
  upload: {
    value: 'upload',
    label: 'Upload'
  },
  leads: {
    value: 'leads',
    label: 'Leads'
  },
  all: {
    label: 'All',
    value: 'all'
  }
};

export const VERIFIED_TYPES = {
  verified: {
    value: 'verified',
    label: 'Verified'
  },
  non_verified: {
    value: 'non_verified',
    label: 'Non-Verified'
  },
  in_progress: {
    value: 'in_progress',
    label: 'In Progress'
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled'
  }
};

export const EF_LIST_PROCESSED_TYPES = {
  processed: {
    value: 'processed',
    label: 'Completed'
  },
  non_processed: {
    value: 'non_processed',
    label: 'Not Processed'
  },
  in_progress: {
    value: 'in_progress',
    label: 'In Progress'
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled'
  }
};

export const PROSPECT_ENRICHED_TYPES = {
  enriched: {
    value: 'enriched',
    label: 'Enriched'
  },
  non_enriched: {
    value: 'non_enriched',
    label: 'Non Enriched'
  },
  in_progress: {
    value: 'in_progress',
    label: 'In Progress'
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled'
  }
};

export const PROSPECT_PERSON_OR_COMPANY_TYPES = {
  all: {
    value: 'all',
    label: 'All'
  },
  person: {
    value: 'person',
    label: 'Person'
  },
  company: {
    value: 'company',
    label: 'Company'
  },
};

export const EMAIL_VERIFY_MODES = {
  'highest_accuracy': 'Highest Accuracy',
  'fastest_turnaround': 'Fastest Turnaround',
}

export const INTEGRATION_PLATFORMS = ['apollo', 'mailchimp', 'active_campaign', 'moosend', 'hubspot', 'mailerlite', 'sendgrid', 'clevertap', 'lemlist', 'go_high_level']

export const POLLING_INTERVAL = 15000; // 15 seconds 

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
];

export const BILLING_TYPE = {
  one_time: {
    value: 'one-time',
    label: 'One-Time',
  },
  annual: {
    value: 'annual',
    label: 'Annual',
  },
  monthly: {
    value: 'monthly',
    label: 'Monthly',
  }
}

export const PRODUCT_DISPLAY_NAME = {
  pay_as_you_go_credits: 'Pay As You Go Credits'
}

export const PRODUCT_TYPE = {
  addon: 'addon',
  plan: 'plan'
}

export const LIST_STATUS = {
  active: 'active',
  expired: 'expired',
  deleted: 'deleted',
  cancelled: 'cancelled'
}
