import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MdMailOutline, MdOutlinePhone } from 'react-icons/md';
import moment from 'moment';

import CustomOverlayTrigger from '../../../widgets/overlay_trigger'
import UserStatsModal from '../../../users_list/user_stats_modal'
import ClipBoardCopy from '../../../widgets/clipboard_copy'
import * as Helpers from '../../../../helpers/helper'
import * as Constants from '../../../../helpers/constants'
import UpdateCoupon from '../../../coupons/update_coupon';
import UserInfoModal from '../../../coupons/user_info_modal';

class TableFormatters {

  /* Common formatters */
  srNoFormater = (cell) => (
    <div className='text-center'>
      {cell.row.index + 1}
    </div>
  )

  creditsFormatter = (cell) => (
    <div className='d-flex px-2 credits'>
      {this.#creditsFormat(cell.getValue())}
    </div>
  )

  recurFormatter = (cell) => (
    <div className='d-flex recur'>
      {this.#recurFormat(cell.getValue())}
    </div>
  )

  subsFormatter = (cell) => (
    <div className='subscription'>
      {this.#subscriptionFormat(cell.getValue())}
    </div>
  )

  thresHoldFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#thresHoldFormat(cell.row.original)}
    </div>
  )

  userRoleFormatter = (cell) => (
    <div className='px-2'>{this.#roleFormat(cell.getValue())}</div>
  )

  userSourceFormatter = (cell) => (
    <div className='px-2'>{cell.getValue()}</div>
  )

  channelSourceFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#channelSourceFormat(cell.getValue())}
    </div>
  )

  channelSourceCampaignFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#channelSourcecampaignFormat(cell.getValue())}
    </div>
  )

  channelSourceQueryFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#channelqueryFormat(cell.getValue())}
    </div>
  )

  affiliateFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#affiliateFormat(cell.getValue())}
    </div>
  )

  signUpIPFormatter = (cell) => (
    <div className='px-2'>{cell.getValue()}</div>
  )

  signUpCountryFormatter = (cell) => (
    <div className='px-2'>{cell.getValue()}</div>
  )

  activatedOnFormatter = (cell) => (
    <div
      className='d-flex px-2 activated_on'
    >
      {this.#joinedOnTimeFormat(cell.getValue())}
    </div>
  )

  createdOnFormatter = (cell) => (
    <div
      className='d-flex px-2 created_on'
    >
      {cell.getValue() ? (
        <span>
          {Helpers.formatDate(cell.getValue(), 'DD-MMM-YYYY @ h:mm:ss a')}
        </span>
      ) : (
        <span>-</span>
      )}
    </div>
  )

  userIdFormatter = (cell) => (
    <div className='px-2'>{cell.getValue()}</div>
  )

  /* Co Formatters */
  coNameFormatter = (cell, client) => (
    <div className='user-list-name-cell-container'>
      <div className='pt-1 pb-3 d-flex align-items-center'>
        <CustomOverlayTrigger
          content={this.#getProjectStatus(cell.row.original)}
          placement='top'
        >
          <FontAwesomeIcon
            icon={cell.row.original.user_type === 'organization' ? 'fa-solid fa-users' : 'fa-solid fa-user'}
            style={{ color: this.#getIconColor(cell.row.original) }}
          />
        </CustomOverlayTrigger>
        {cell.row.original.name ? (
          <span className='ps-2'>
            <CustomOverlayTrigger
              content={cell.row.original.name}
              placement='top'
            >
              <Link
                to={`/${client}/individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
                target='_blank'
                rel='noopener noreferrer'
                className='text-decoration-none co-name-format'
              >
                {cell.row.original.name}
              </Link>
            </CustomOverlayTrigger>
          </span>
        ) : (
          <span>-</span>
        )}
        <div className='ms-auto'>
          {this.#getPaidTag(cell.row.original) ? (
            <Badge
              bg='success'
            >
              Paid
            </Badge>
          ) : null}
        </div>
      </div>
      <div className='user-list-table-icon-wrapper'>
        <div className='pe-2'>
          <UserStatsModal
            cell={cell}
            selectedUser={cell.row.original}
            client={client}
          />
        </div>
        <div className='px-2'>
          <CustomOverlayTrigger
            content={`click here to get the activities of ${cell.getValue() || 'user'}`}
            placement='bottom'
          >
            <Link
              to={`/${client}/individual_user_activity?user_id=${cell.row.original._id}`}
              target='blank'
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon='fa-brands fa-wpexplorer'
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
        <div className='px-2'>
          <CustomOverlayTrigger
            content={`Click here to view the matomo activity logs of ${cell.row.original.name}`}
            placement='bottom'
          >
            <Link
              to={`${CLEAROUT_BASE_URL}/matomo/getvisitorprofile?user_id=${cell.row.original._id}&site_id=${MATOMO_SITE_ID}`}
              target='blank'
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon='fa-solid fa-arrow-up-right-from-square'
              />
            </Link>

          </CustomOverlayTrigger>
        </div>
        <div className='px-2'>
          <CustomOverlayTrigger
            content={`Click here to get the email verifier lists of ${cell.row.original.name}`}
            placement='bottom'
          >
            <Link
              to={`/${client}/email_verifier_lists?user_email=${encodeURIComponent(cell.row.original.email)}`}
              //to={`/${client}/email_verifier_list?user_email=${cell.row.original.email}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon='fa-solid fa-sheet-plastic'
              />
            </Link>

          </CustomOverlayTrigger>
        </div>
        <div className='px-2'>
          <CustomOverlayTrigger
            content={`Click here to get the email finder lists of ${cell.row.original.name}`}
            placement='bottom'
          >
            <Link
              to={`/${client}/email_finder_lists?user_email=${encodeURIComponent(cell.row.original.email)}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon='fa-solid fa-list-ul'
              />
            </Link>

          </CustomOverlayTrigger>

        </div>
        <div className='px-2'>
          <CustomOverlayTrigger
            content={`Click here to get the prospect lists of ${cell.row.original.name}`}
            placement='bottom'
          >
            <Link
              to={`/${client}/prospect_lists?user_email=${encodeURIComponent(cell.row.original.email)}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon='fa-solid fa-rectangle-list'
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
      </div>
    </div>
  )

  coEmailFormatter = (cell, client) => {
    const name = cell.row.original.name
    const email = cell.getValue()
    return (
      <div className='d-flex align-items-center email-cell'>
        <MdMailOutline className="me-2" />
        {name ? (
          <CustomOverlayTrigger
            placement={'top'}
            content={email}
          >
            <span>{_.truncate(email, { 'length': 40, 'omission': '...' })}</span>
          </CustomOverlayTrigger>

        ) : (
          <span>
            <Link
              to={`/${client}/individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
              className='text-decoration-none mb-4'
            >
              <CustomOverlayTrigger
                placement={'top'}
                content={<span className='text-wrap'>{email}</span>}
              >
                <span>{_.truncate(email, { 'length': 40, 'omission': '...' })}</span>
              </CustomOverlayTrigger>
            </Link>
          </span>
        )}
        <div className="ms-auto user-list-table-icon-wrapper">
          <ClipBoardCopy textToCopy={email} />
        </div>
      </div>
    )
  }

  coPhoneNumberFormatter = (cell) => (
    <div
      className='d-flex justify-content-between px-2 phone-cell'
    >
      {cell.getValue() ? (
        <span>{cell.getValue()}</span>
      ) : (
        <span>-</span>
      )}
      {cell.getValue() ? (
        <ClipBoardCopy textToCopy={cell.getValue()} />
      ) : null}
    </div>
  )

  contactFormatter = (cell, client) => {
    const name = cell.row.original.name;
    const email = cell.getValue();
    const phone = cell.row.original.phone_number;

    return (
      <div className="d-flex flex-column px-2 gap-1 email-cell">
        {/* Email Section */}
        <div className="d-flex align-items-center">
          <MdMailOutline className="me-2" />
          {name ? (
            <CustomOverlayTrigger
              placement={'top'}
              content={<span className="text-wrap">{email}</span>}
            >
              <span>{_.truncate(email, { length: 30, omission: '...' })}</span>
            </CustomOverlayTrigger>
          ) : (
            <Link
              to={`/${client}/individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
              className="custom-link"
            >
              <CustomOverlayTrigger
                placement={'top'}
                content={<span className="text-wrap" >{email}</span>}
              >
                <span>{_.truncate(email, { length: 30, omission: '...' })}</span>
              </CustomOverlayTrigger>
            </Link>
          )}
          <div className="ms-auto user-list-table-icon-wrapper">
            <ClipBoardCopy textToCopy={email} />
          </div>
        </div>
        {/* Phone Section */}
        <div className="d-flex align-items-center">
          <MdOutlinePhone className="me-2" />
          {phone ? <span>{phone}</span> : <span>-</span>}
          {phone ? (
            <div className="ms-auto user-list-table-icon-wrapper">
              <ClipBoardCopy textToCopy={phone} />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  coActivatedByFormatter = (cell) => {
    let additionalInfo = cell.getValue()
    let val = '-'

    if (additionalInfo && additionalInfo.value && additionalInfo.value.activation_link_type)
      val = additionalInfo.value.activation_link_type

    return (
      <div className='px-2'>
        {Helpers.capitalizeFirstLetter(val)}
      </div>
    )
  }

  coSignUpFormatter = (cell) => (
    <div className='d-flex'>
      {this.#ssoFormat(cell.getValue())}
    </div>
  )

  coLastPurchasedFormatter = (cell) => (
    <div className='d-flex px-2 last_purchased'>
      {this.#lastPurchasedOnFormat(cell.getValue())}
    </div>
  )

  coTotalAmountPaidFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#formatTotalAmountPaid(cell.getValue())}
    </div>
  )

  coPaymentCountFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#totalPaidCount(cell.getValue())}
    </div>
  )

  coAccountDeleteReasonFormat = (cell) => {
    return (
      <div>
        {this.#accountDeleteReasonFormat(cell.getValue())}
      </div>
    );
  };

  coSubsFormatter = (cell) => {

    let subscription = cell.getValue()
    let data = Helpers.getCoSubsTable(subscription)

    return (
      <div className="subscription">
        {data}
      </div>
    )
  }

  coLastSubsFormatter = (cell) => {

    let subscription = cell.getValue()
    let data = Helpers.getCoLastSubsTable(subscription)

    return (
      <div className="subscription">
        {data}
      </div>
    )
  }

  /* Private functions */

  #getProjectStatus = (row) => {
    if (row.account_status === 'removed') {
      return 'Removed'
    } else if (!row.activated_on || row.activated_on === 'Yet to Activate') {
      return 'Yet to activate'
    } else if (row.status === 'active') {
      return 'Active'
    } else {
      return 'Inactive'
    }
  }

  #getIconColor = (row) => {
    if (row.account_status === 'removed') {
      return '#ff6767'
    } else if (!row.activated_on || row.activated_on === 'Yet to Activate') {
      return '#a3a3a3'
    } else if (row.status === 'active') {
      return '#198754'
    } else {
      return '#e48725'
    }
  }

  #getOrgNameColor = (row) => {
    if (row.deleted_on) {
      return '#ff6767'
    } else if (row.status === 'inactive') {
      return '#e48725'
    } 
    else {
      return '#4e4f52'
    }
  }

  #getPaidTag(user) {
    let isPaid = false
    if (user.tags && user.tags.length > 0) {
      isPaid = user.tags.includes('paid')
    }
    return isPaid
  }


  #getCopPaidTag(user) {
    let isPaid = false;
    if (user.tags && user.tags.length > 0) {
      user.tags.forEach(tag => {
        if (tag.type === 'paid') {
          isPaid = true;
        }
      });
    }
    return isPaid;
  }

  #creditsFormat = (cell) => {
    return (
      <div className='credits-format'>
        {cell.available !== null ? (
          <p
            className='mb-0'
            title='Remaining credits'
          >
            Remaining credits : {Helpers.isNumber(cell.total) ? Number(cell.total).toLocaleString() : '-'}
          </p>
        ) : (
          'Remaining credits : Unlimited'
        )}
      </div>
    )
  }

  #ssoFormat = (cell) => {
    if (cell.value && cell.value.signup_source) {
      let ssoDetails = Helpers.capitalizeFirstLetter(cell.value.signup_source) + ' / ' + Helpers.capitalizeFirstLetter(cell.value.signup_method)
      return <div>{ssoDetails}</div>
    } else {
      return 'Direct / Email'
    }
  }

  #recurFormat = (cell) => {
    if (cell && cell.credits) {
      let data = Helpers.getRecurTable(cell)
      return data
    } else {
      return '-'
    }
  }

  #subscriptionFormat = (cell) => {
    if (cell && cell.status) {
      let data = Helpers.getSubsTable(cell)
      return data
    } else {
      return '-'
    }
  }

  #thresHoldFormat = (row) => {
    let data = '-'
    let thresholdSign = ''
    if (row.settings?.low_credit_balance) {
      data = row.settings?.low_credit_balance.min_threshold
      if (data >= row.credits?.total) {
        thresholdSign = 'red'
      }
    }
    return <span style={{ color: thresholdSign }}>{data.toLocaleString()}</span>
  }

  #roleFormat = (cell) => {
    let data = cell === 'analyst' ? 'Executive' : cell
    return data ? Helpers.capitalizeFirstLetter(data) : '-'
  }

  #channelSourceFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelSource(cell)}</div>
    } else {
      return '-'
    }
  }

  #channelSourcecampaignFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelSourceCampaign(cell)}</div>
    } else {
      return '-'
    }
  }

  #channelqueryFormat = (cell) => {
    if (cell) {
      return <div>{Helpers.getChannelQuery(cell)}</div>
    } else {
      return '-'
    }
  }

  #affiliateFormat = (cell) => {
    return cell ? cell : '-'
  }

  #lastPurchasedOnFormat = (cell) => {
    if (cell && cell.is_paid) {
      return Helpers.formatDate(cell.last_purchased_on, 'DD-MMM-YYYY @ h:mm:ss a')
    } else {
      return '-'
    }
  }

  #formatTotalAmountPaid = (tags) => {
    if (tags) {
      const totalPaid = tags.total
      return Number(totalPaid).toLocaleString('en-US', { currency: 'USD' })
    } else {
      return 0
    }
  }

  #totalPaidCount = (cell) => {
    if (cell) {
      return cell.count
    } else {
      return 0
    }
  }

  #joinedOnTimeFormat = (cell) => {
    let date
    if (cell && cell !== 'Yet to Activate') {
      date = Helpers.formatDate(cell, 'DD-MMM-YYYY @ h:mm:ss a')
    } else {
      date = 'Yet to Activate'
    }
    return <span title={date}>{date}</span>
  }

  #accountDeleteReasonFormat = (cell) => {
    let reason;
    if (cell) {
      reason = Constants.ACCOUNT_DELETE_REASONS[cell]
      return (
        <div>
          {reason || Helpers.capitalizeFirstLetter(cell)}
        </div>
      );
    } else {
      return (
        <div>-</div>
      );
    }
  };

  #orgCreditsFormat = (cell, row) => {
    return (
      <div className="credits-format">
        {cell.available !== null ? (
          <p
            className="mb-0"
            title="Remaining credits"
          >
            Remaining credits : {Helpers.getRemainingCredits(cell)}
          </p>
        ) : (
          'Remaining credits : Unlimited'
        )}
        <div>
          <small>Remaining daily : {cell.available_daily_limit ? cell.available_daily_limit.toLocaleString() : '-'}</small>
        </div>
        <div>
          <small>Allowed daily : {row.limits.allowed_daily_limit ? row.limits.allowed_daily_limit.toLocaleString() : '-'}</small>
        </div>
        {row.credits.available && row.credits.subs ? (
          <small>Pay as you go credits : {row.credits.available.toLocaleString()}</small>
        ) : null}
        <div>
          {row.credits.subs ? (
            <small>Subscription credits : {row.credits.subs.toLocaleString()}</small>
          ) : null}
        </div>
        <div>
          {row.credits.recur ? (
            <small>Recurring Credits : {row.credits.recur.toLocaleString()}</small>
          ) : null}
        </div>
      </div>
    )
  }

  #orgMemberseatsFormat = (row) => {
    const usedSeats = row.used_seats_count;
    const allocatedSeats = row.plan.user_create_limit;
    return (
      <div>
        <div>
          <p
            title='Organization Member Seats'
            className="mb-0"
          >
            <small>{usedSeats} Occupied / {allocatedSeats} Allocated</small>
          </p>
        </div>
      </div>
    );
  }

  #copFormatTotalAmountPaid = (tags) => {
    if (tags) {
      const totalPaid = tags[0]?.total ? tags[0]?.total : 0
      return Number(totalPaid).toLocaleString('en-US', { currency: 'USD' })
    } else {
      return 0
    }
  }

  /* Cop Formatters */
  copNameFormatter = (cell, client) => (
    <div className='user-list-name-cell-container'>
      <div className='py-2'>
        <CustomOverlayTrigger
          content={this.#getProjectStatus(cell.row.original)}
          placement='top'
        >
          <FontAwesomeIcon
            icon={cell.row.original.user_type === 'organization' ? 'fa-solid fa-users' : 'fa-solid fa-user'}
            style={{ color: this.#getIconColor(cell.row.original) }}
          />
        </CustomOverlayTrigger>
        {cell.row.original.name ? (
          <span className='ps-2'>
            <CustomOverlayTrigger
              content={cell.row.original.name}
              placement='top'
            >
              <Link
                to={`/${client}/individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
                target='_blank'
                rel='noopener noreferrer'
                className='text-decoration-none co-name-format'
              >
                {cell.row.original.name}
              </Link>
            </CustomOverlayTrigger>
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
      <div className='d-flex'>
        <div className='paid-tag'>
          {this.#getCopPaidTag(cell.row.original) ? (
            <Badge
              bg='success badge-outline'
            >
              Paid
            </Badge>
          ) : null}
        </div>
        <div
          className='d-flex'
        >
          <div className='px-2'>
            <CustomOverlayTrigger
              content={`click here to get the activities of ${cell.getValue()}`}
              placement='bottom'
            >
              <Link
                to={`/clearoutphone/individual_user_activity?user_id=${cell.row.original._id}`}
                className='co-name-cell-link'
              >
                <FontAwesomeIcon
                  icon='fa-brands fa-wpexplorer'
                />
              </Link>
            </CustomOverlayTrigger>
          </div>
          <div className='px-2'>
            <CustomOverlayTrigger
              content={`Click here to view the matomo activity logs of ${cell.row.original.name}`}
              placement='bottom'
            >
              <Link
                to={`${CLEAROUT_PHONE_BASE_URL}/matomo/getvisitorprofile?user_id=${cell.row.original._id}&site_id=${COP_MATOMO_SITE_ID}`}
                target='blank'
                className='co-name-cell-link'
              >
                <FontAwesomeIcon
                  icon='fa-solid fa-arrow-up-right-from-square'
                />
              </Link>

            </CustomOverlayTrigger>
          </div>
          <div className='px-2'>
            <CustomOverlayTrigger
              content={`Click here to get the Phone lists of ${cell.row.original.name}`}
              placement='bottom'
            >
              <Link
                to={`/${client}/lists?user_email=${encodeURIComponent(cell.row.original.email)}`}
                target='_blank'
                rel="noopener noreferrer"
                className='co-name-cell-link'
              >
                <FontAwesomeIcon
                  icon='fa-solid fa-sheet-plastic'
                />
              </Link>
            </CustomOverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  )

  copEmailFormatter = (cell, client) => {
    const name = cell.row.original.name
    const email = cell.getValue()
    return (
      <div className='d-flex align-items-center email-cell text-wrap'>
        {name ? (
          <span className='text-break'>{email}</span>
        ) : (
          <span>
            <Link
              to={`/${client}/individual_user_info?user_id=${cell.row.original._id}&user_type=${cell.row.original.user_type}`}
              className='text-decoration-none text-break'
            >
              {email}
            </Link>
          </span>
        )}
        <div className="ms-auto user-list-table-icon-wrapper">
          <ClipBoardCopy textToCopy={email} />
        </div>
      </div>
    )
  }

  copTotalAmountPaidFormatter = (cell) => (
    <div className='d-flex px-2'>
      {this.#copFormatTotalAmountPaid(cell.getValue())}
    </div>
  )

  copPaymentCountFormatter = (cell) => {
    let tags = cell.getValue()
    let val = '-'

    if (tags && tags.length) {
      let paidTag = tags.find((v) => v.type === 'paid')
      if (Object.keys(paidTag).length) {
        val = paidTag.count
      }
    }

    return (
      <div className='d-flex px-2'>{val}</div>
    )
  }

  copLastPurchasedFormatter = (cell) => {
    let tags = cell.getValue()
    let val = '-'

    if (tags && tags.length) {
      let paidTag = tags.find((v) => v.type === 'paid')
      if (Object.keys(paidTag).length) {
        val = this.#lastPurchasedOnFormat(paidTag)
      }
    }

    return (
      <div className='d-flex px-2 last_purchased'>
        {val}
      </div>
    )
  }

  ///coupons formatters
  couponDetails = (cell, getClearoutUsers, client, dropdowns) => {
    const { row: { original } } = cell;
    const row = cell.row.original;
    let userId = row.user_id
    return (
      <div className='text-wrap'>
        <div className='d-flex justify-content-between'>
          <p className='m-0'>
            <b>Name:</b> {original.name}
          </p>
          <UpdateCoupon
            cellData={cell}
            getClearoutUsers={getClearoutUsers}
            client={client}
            applicableUserId={userId}
            dropdowns={dropdowns}
          />
        </div>
        <p className='m-0 py-2'>
          <b>Type:</b> {original.discount?.type || Helpers.capitalizeFirstLetter(original.type)}
        </p>
      </div>
    );
  };

  redeemCountFormat = (cell) => {
    const row = cell.row.original;
    let redeemCount = row.redeemed_count
    let allowedRedeemCount = row.allowed_redeem_count
    return <p>{redeemCount}/{allowedRedeemCount}</p>
  }

  discountValuesFormat = (cell) => {
    const row = cell.row.original;

    if (row.discount) {
      return (
        <div>
          <p>Min: {Number(row.discount.min).toLocaleString()}</p>
          <p>Max: {Number(row.discount.max).toLocaleString()}</p>
          <p>Value: {Number(row.discount.value).toLocaleString()}</p>
        </div>
      );
    } else if (row.bonus) {
      return (
        <div>
          <p>Min: {Number(row.bonus.min).toLocaleString()}</p>
          <p>Max: {Number(row.bonus.max).toLocaleString()}</p>
          <p>Duration: {Number(row.bonus.duration_in_month).toLocaleString()}</p>
        </div>
      );
    } else {
      return 0;
    }
  };

  couponId = (cell) => {
    const row = cell.row.original;
    let couponId = row.coupon_id
    return (
      <div className='d-flex justify-content-between text-wrap'>
        <p className='m-0'>{couponId}</p>
        <ClipBoardCopy textToCopy={couponId} />
      </div>
    )
  }

  affiliatedToFormat = (cell) => {
    const row = cell.row.original;
    let affiliatedTo = row.affiliated_to
    return (
      <div className='d-flex justify-content-between'>
        <p className='m-0'>{affiliatedTo}</p>
      </div>
    )
  }

  statusFormat = (cell) => (
    <div className='px-2'>{cell.getValue()}</div>
  )

  formatDate = (cell) => {
    const row = cell.row.original;
    let affiliatedTo = row.expiry
    return (
      <>
        <p className='m-0'> {moment(affiliatedTo).utc().format('DD/MM/YYYY HH:mm:ss')}</p>
      </>
    )
  }
  createdByFormat = (cell) => (
    <div>{cell.getValue()}</div>
  )

  applicableFormat = (cell, getClearoutUsers, client) => {
    const row = cell.row?.original;
    let userId = row.user_id

    return (
      <div className='px-2 d-flex justify-content-between'>
        <p>{cell.getValue()}</p>
        {userId ? (
          <UserInfoModal
            userId={userId}
            client={client}
          />
        ) : null}
      </div>
    )
  }

  // co org formatters

  coOrgNameFormatter = (cell, client) => (
    <div className='user-list-name-cell-container'>
      <div className="pb-2">
        {cell.row.original.name ? (
          <span className="ps-2">
            <CustomOverlayTrigger
              content={cell.row.original.name}
              placement="top"
            >
              <Link
                to={`/${client}/organization_info?org_id=${cell.row.original.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: this.#getOrgNameColor(cell.row.original)
                }}
                className='text-decoration-none co-name-format'
              >
                {cell.row.original.name}
              </Link>
            </CustomOverlayTrigger>
          </span>
        ) : (
          <span>-</span>
        )}
      </div>
      <div
        className="user-list-table-icon-wrapper"
      >
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the email verifier lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link
              to={`/${client}/email_verifier_lists?org_id=${cell.row.original.id}&org_name=${cell.row.original.name}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon="fa-solid fa-sheet-plastic"
              />
            </Link>

          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the email finder lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link
              to={`/${client}/email_finder_lists?org_id=${cell.row.original.id}&org_name=${cell.row.original.name}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
              />
            </Link>

          </CustomOverlayTrigger>

        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the prospect lists of ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link
              to={`/${client}/prospect_lists?org_id=${cell.row.original.id}&org_name=${cell.row.original.name}`}
              target='_blank'
              rel="noopener noreferrer"
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon="fa-solid fa-rectangle-list"
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <CustomOverlayTrigger
            content={`Click here to get the users list of organization - ${cell.row.original.name}`}
            placement="bottom"
          >
            <Link
              to={`/${client}/org_members_list?org_id=${cell.row.original.id}&org_name=${cell.row.original.name}`}
              target='blank'
              className='co-name-cell-link'
            >
              <FontAwesomeIcon
                icon="fa fa-users fa-lg"
              />
            </Link>
          </CustomOverlayTrigger>
        </div>
        <div className="px-2">
          <UserStatsModal
            cell={cell}
            selectedUser={cell.row.original}
            client={client}
          />
        </div>
      </div>
    </div>
  )

  orgUrlFormat = (cell) => (
    <div className="px-2">
      <a
        href={cell.getValue()}
        target='blank'
        className='text-decoration-none'
      >
        {cell.getValue()}
      </a>
    </div>
  )

  creditsOrgFormatter = (cell) => {
    const rowData = cell.row.original;
    return (
      <div className="d-flex px-2 credits">
        {this.#orgCreditsFormat(cell.getValue(), rowData)}
      </div>
    )
  }

  memberSeatsFormat = (cell) => {
    const rowData = cell.row.original;
    return (
      <div className="d-flex px-2 credits">
        {this.#orgMemberseatsFormat(rowData)}
      </div>
    );
  }
}


export default new TableFormatters()